/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BaseMasterData } from '../model/common/base-master-data.model';
import { APIResponseWrapper } from '../model/common/api-response-wrapper';

@Injectable()
export class BaseMasterDataService {

    restApiUrl: string;

    constructor(private http: HttpClient) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    getBaseMasterData(cultureCode: string) {
        return this.http.get<APIResponseWrapper<BaseMasterData>>(`${this.restApiUrl}shared-endpoint/base-master?cultureCode=${cultureCode}`);
    }
    
}