import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import * as firebaseAuth from 'firebase/auth';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';

import { MessageService } from 'primeng/api';

import { Driver } from '../model/driver/driver';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from '../service/driver.service';
import { APIResponseWrapper } from '../model/common/api-response-wrapper';
import { SessionStorageService } from '../service/session-storage.service';
import { BaseMasterDataService } from '../service/base-master-data.service';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  returnUrl: string | undefined = '';

  constructor(private angularFireAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private authService: AuthService,
    private driverService: DriverService,
    private sessionStorageService: SessionStorageService,
    private baseMasterDataService: BaseMasterDataService,
    private toasterService: MessageService, private router: Router) { }

  ngOnInit(): void {
    this.angularFireAuth.authState.subscribe(response => {
      if (response) {
        console.log('Logged in :)');
        this.loginToBackend(response);
      } else {
        console.log('Logged out :(');
      }
    });
    this.returnUrl = this.sessionStorageService.getReturnUrl();
  }

  successCallback(firebaseUISignInSuccessWithAuthResult: FirebaseUISignInSuccessWithAuthResult) {
    this.loginToBackend(firebaseUISignInSuccessWithAuthResult.authResult.user);
  }

  private loginToBackend(user: unknown) {
    let firebaseUser = user as firebaseAuth.User;

    this.authService.getCurrentUserAccessToken().subscribe(
      token => {
        this.sessionStorageService.saveAccessToken(token, new Date().getTime());
        this.driverService.getDriverById(firebaseUser.uid).subscribe((data: APIResponseWrapper<Driver>) => {
          console.log(data);
          if (!data.success) {
            console.log("No driver found");
            this.toasterService.add({
              severity: 'error',
              summary: 'No Profile',
              detail: 'No profile found, please ask Admin to register your account!'
            });
          } else {
            this.sessionStorageService.saveDriver(data.data);
            this.toasterService.add({
              severity: 'success',
              summary: 'Logged In',
              detail: 'Signed in as ' + firebaseUser.phoneNumber + ', redirecting to Home page'
            });
            const queryParams = this.extractQueryParams(this.returnUrl ?? '');
            this.returnUrl = this.returnUrl?.substring(0, this.returnUrl.indexOf('?'));
            this.router.navigate([/*this.returnUrl == undefined ? */'/order'/* : this.returnUrl*/]/*, { queryParams }*/);
            this.baseMasterDataService.getBaseMasterData("en-US")
              .subscribe(baseMasterData => {
                console.log(baseMasterData);
                this.sessionStorageService.saveBaseMasterData(baseMasterData.data);
                /*this.isLoading = false;
                this.toolbarService.updateLoggedInStatus(true);
                this.toolbarService.updateCustomer(customer);*/
              });
          }
        });
      },
      err => {
        //this.isLoading = false;
      }
    );
    this.authService.getCurrentUserRefreshToken().subscribe(
      response => {
        this.sessionStorageService.saveRefreshToken(response);
      }
    )
    this.sessionStorageService.saveUser(firebaseUser);

  }

  errorCallback(firebaseUISignInFailure: FirebaseUISignInFailure) {
    this.toasterService.add({
      severity: 'error',
      summary: 'FirebaseUI Error',
      detail: firebaseUISignInFailure.code
    });
  }

  uiShownCallback() {
    //throw new Error('Method not implemented.');
  }

  extractQueryParams(url: string): { [key: string]: string } {
    const queryParams: { [key: string]: string } = {};
  
    const urlParts = url.split('?');
    if (urlParts.length > 1) {
      const queryStrings = urlParts[1].split('&');
      queryStrings.forEach(queryString => {
        const [key, value] = queryString.split('=');
        queryParams[key] = value;
      });
    }
  
    return queryParams;
  }

}
