/* eslint-disable */
import { GeoAddress } from "../model/common/geo-address.model";
import { GeoElement } from "../model/common/geo-element.model";


export function getLocality(geoAddress: GeoAddress) : string | undefined {
    return getAddressComponent('locality', geoAddress);
}

export function getSubLocality(geoAddress: GeoAddress) : string | undefined {
    return getAddressComponent('sublocality', geoAddress);
}

function getAddressComponent(component: string, geoAddress: GeoAddress): string | undefined {
    let componentElement: GeoElement | undefined = geoAddress.addressElements
                ?.filter(geoElement => geoElement.types
                    ?.find(addressComponentType => addressComponentType === component)).pop();
    return componentElement?.name;
}

