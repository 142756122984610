import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from 'src/environments/environment';
import jsPDF from 'jspdf';
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { MapDirectionsService } from '@angular/google-maps';

import { JobAttachment } from '../../model/order-view/job-attachment';
import { OrderDeliveryView } from '../../model/order-view/order-delivery';
import { OrderQueryView } from '../../model/order-view/order-query-view';
import { SessionStorageService } from '../../service/session-storage.service';
import * as geoAddressHelpers from '../../helper/geo-address.helper'
import { synchronized } from 'synchronized-ts';
import { OrderJobStatus } from 'src/app/model/order-view/order-job-status';
import { JobStatus, jobStatusInfoMap } from 'src/app/model/order-view/job-status';
import { PaymentStatus } from 'src/app/model/order-view/payment-status';
import { OrderDeliveryService } from 'src/app/service/order-delivery.service';
import { lastValueFrom } from 'rxjs';



@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent {

  queryId: string;
  deliveryNumber: string;
  orderQuery: OrderQueryView;
  orderDelivery: OrderDeliveryView;
  pickupSubLocality: string | undefined;
  dropoffSubLocality: string | undefined;
  pickupLocality: string | undefined;
  dropoffLocality: string | undefined;
  deliveryFilesList: JobAttachment[] = [];
  deliveryFilesCount: number = 0;
  isLoading: boolean = true;
  orderJobStatus: OrderJobStatus = new OrderJobStatus();
  breadcrumbItems: MenuItem[];
  homeBreadcrumb: MenuItem;
  currentPosition: GeolocationPosition;
  selectedImage: any = null;
  pdfUploadProgress: number = 0;
  pdfUploadOngoing: boolean = false;


  // Timeline data
  timelineEvents: any[] = [];

  googleMap: google.maps.Map;

  /*@ViewChild(google.maps.InfoWindow, { static: false })
  infoWindow: google.maps.InfoWindow
  infoContent = 'testing all'*/

  showRoute = false;
  zoom = 12
  vehicleMarkerImage = "https://storage.googleapis.com/prologix.appspot.com/basic_data/admin_files/TractorUnitBlack.png"
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8
  }
  vehicleLocation: google.maps.LatLngLiteral;
  pickupLocation: google.maps.LatLngLiteral;
  dropoffLocation: google.maps.LatLngLiteral;
  directionsResults: google.maps.DirectionsResult;

  constructor(private route: ActivatedRoute,
    private database: AngularFireDatabase,
    private messageService: MessageService,
    private orderDeliveryService: OrderDeliveryService,
    private mapDirectionsService: MapDirectionsService,
    private sessionStorageService: SessionStorageService) {
  }

  ngOnInit(): void {
    this.vehicleLocation = { lat: 0.0, lng: 0.0 };
    this.pickupLocation = { lat: 0.0, lng: 0.0 };
    this.dropoffLocation = { lat: 0.0, lng: 0.0 };
    this.route.queryParams.subscribe(async params => {
      console.log(params); // { order: "popular" }
      this.deliveryNumber = params['deliveryNumber'];
      this.queryId = params['queryId'];
      console.log(this.deliveryNumber);
      console.log(this.queryId);

      this.orderQuery = this.sessionStorageService.getCurrentOrder()!!;
      if (this.orderQuery == null) {
        this.orderQuery = await lastValueFrom(this.orderDeliveryService.getDeliveryByOrderAndDeliveryNumber(this.queryId, this.deliveryNumber));
        this.orderDelivery = (Object.entries(this.orderQuery?.deliveries).map(deliveryEntry => deliveryEntry[1]) as OrderDeliveryView[])?.[0]!!;
      } else {
        this.orderDelivery = this.sessionStorageService.getCurrentDelivery()!!;
      }
      console.log(this.orderQuery);
      console.log(this.orderDelivery);

      this.orderDelivery.deliveryDriver = this.sessionStorageService.getDriver()!!;
      this.orderDelivery.deliveryVehicle = this.sessionStorageService.getDriver()!!.currentVehicle!!;
      this.deliveryFilesList = this.orderDelivery?.filesList!!;
      this.deliveryFilesCount = this.orderDelivery?.filesList?.length!!;
      this.isLoading = false;

      this.pickupLocality = geoAddressHelpers.getLocality(this.orderQuery?.pickupAddress);
      this.pickupSubLocality = geoAddressHelpers.getSubLocality(this.orderQuery?.pickupAddress);
      this.dropoffLocality = geoAddressHelpers.getLocality(this.orderQuery?.dropoffAddress);
      this.dropoffSubLocality = geoAddressHelpers.getSubLocality(this.orderQuery?.dropoffAddress);

      this.breadcrumbItems = [{ label: 'Orders', routerLink: '/order' },
      { label: this.orderDelivery?.deliveryText }];
      this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/dashboard' };

      navigator.geolocation.watchPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.vehicleLocation.lat = position.coords.latitude;
        this.vehicleLocation.lng = position.coords.longitude;
      });

      // Fetch pickup and dropoff locations from your data (e.g., from your orderDelivery object)
      this.pickupLocation = { lat: this.orderQuery.sourceLocation.location.latitude, lng: this.orderQuery?.sourceLocation.location.longitude };
      this.dropoffLocation = { lat: this.orderQuery.destinationLocation.location.latitude, lng: this.orderQuery.destinationLocation.location.longitude };
      //this.vehicleLocation = { lat: this.orderQuery.sourceLocation.location.latitude, lng: this.orderQuery?.sourceLocation.location.longitude };

      const request: google.maps.DirectionsRequest = {
        destination: this.dropoffLocation,
        origin: this.pickupLocation,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC
      };
      this.mapDirectionsService.route(request).subscribe(directionsResponse => {
        this.directionsResults = directionsResponse.result!;
        this.showRoute = true;
      });

      this.getLiveJobWithStatus(this.orderQuery, this.orderDelivery);
    });
  }

  showDate(longDate: number | undefined) {
    return longDate != undefined ? formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US') : undefined;
  }

  openInfo(_t13: HTMLElement) {
    throw new Error('Method not implemented.');
  }


  getLiveJobWithStatus(orderQuery: OrderQueryView, orderDelivery: OrderDeliveryView) {
    const jobStatusRef = this.database.list(`orderJob/${orderQuery.queryId}/${orderDelivery.deliveryText}`);
    jobStatusRef.stateChanges(['child_added']).subscribe(snapShot => {
      console.log("Job Status Added: " + JSON.stringify(snapShot));
      this.updateOrderJobStatus(snapShot);

    });
    jobStatusRef.stateChanges(['child_changed']).subscribe(snapShot => {
      console.log("Job Status Change: " + JSON.stringify(snapShot));
      //this.updateOrderJobStatus(snapShot);
    });
  }

  @synchronized
  async updateJobStatuses(jobStatuses: JobStatus[]) {
    for (let i = 0; i < jobStatuses.length; i++) {
      await this.updateJobStatus(jobStatuses[i]);
      console.log("Job Status Updated: " + jobStatuses[i]);
    }
  }

  @synchronized
  updateJobStatus(jobStatus: JobStatus) {
    this.orderJobStatus.jobStatus = jobStatus;
    this.orderJobStatus.jobStatusMap[jobStatus] = Date.now();
    return Promise.all([
      this.database.object(`orderJob/${this.queryId}/${this.deliveryNumber}/jobStatus`).set(jobStatus.valueOf()),
      this.database.object(`orderJob/${this.queryId}/${this.deliveryNumber}/jobStatusMap/${jobStatus.valueOf()}`).set(Date.now()),
      Promise.resolve(() => setTimeout(() => { }, 2000))
    ])
  }

  @synchronized
  updateOrderJobStatus(snapShot: SnapshotAction<any>) {
    if (snapShot.key?.includes('jobStatusMap'))
      this.orderJobStatus.jobStatusMap = snapShot.payload.val() as Map<JobStatus, number>;
    else if (snapShot.key?.includes('jobStatus'))
      this.orderJobStatus.jobStatus = snapShot.payload.val() as JobStatus;
    else if (snapShot.key?.includes('paymentStatus'))
      this.orderJobStatus.paymentStatus = snapShot.payload.val() as PaymentStatus;
  }

  openCamera() {
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedImage = null;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  uploadImage() {
    if (!this.selectedImage) {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please capture an image first.' });
      return;
    }

    this.pdfUploadOngoing = true;

    // Create a unique file name
    const fileName = `waybill_${this.deliveryNumber}_signed.pdf`;
    const storageRef = ref(storage, fileName);

    // Convert image to PDF
    const doc = new jsPDF();
    doc.addImage(this.selectedImage, 'JPEG', 0, 0, this.selectedImage.width, this.selectedImage.height); // Adjust dimensions as needed

    // Get PDF data as a Blob
    const pdfBlob = doc.output('blob');

    // Upload the PDF blob to Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, pdfBlob);

    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        this.pdfUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.pdfUploadProgress = Math.round(this.pdfUploadProgress);
        if (this.pdfUploadProgress > 10)
          this.pdfUploadProgress = this.pdfUploadProgress - 5;
        console.log('Upload is ' + this.pdfUploadProgress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Error uploading image:", error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'PDF upload failed.' });
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.updateJobStatus(JobStatus.JOB_COMPLETION_ACKNOWLEDGE);
          console.log('File available at', downloadURL);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'PDF uploaded successfully.' });
          //update firestore collection to add a new document
          let jobAttachment: JobAttachment = {
            fileName: fileName,
            fileUrl: downloadURL,
            fileSize: this.selectedImage.length,
            fileType: 'Waybill',
            createdAt: new Date().toISOString()
          };
          this.orderDeliveryService.addDeliveryAttachment(jobAttachment, this.queryId, this.deliveryNumber).subscribe(res => {
            console.log(res);
            if (res.success) {
              this.deliveryFilesList.push(jobAttachment);
              this.deliveryFilesCount = this.deliveryFilesList.length;
              this.pdfUploadProgress = 100;
              this.pdfUploadOngoing = false;
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'PDF added successfully to delivery' });
            }
          });

        }).catch((error) => {
          console.error("Error getting download URL:", error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get download URL.' });
        });
      }
    );
  }

  checkStatus(jobStatusString: string): boolean {
    let jobStatusToCheck = jobStatusString as JobStatus;
    let jobStatusToCheckPosition = Object.entries(jobStatusInfoMap)
      .filter(([status]) => status === jobStatusToCheck)
      .map(entry => entry[1].position);
    let jobStatusCurrentPosition = Object.entries(jobStatusInfoMap)
      .filter(([status]) => status === this.orderJobStatus.jobStatus)
      .map(entry => entry[1].position);

    return jobStatusToCheckPosition < jobStatusCurrentPosition;
  }

  async navigateToPickup() {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.orderQuery.sourceLocation.location.latitude},${this.orderQuery.sourceLocation.location.longitude}`, '_blank');
    await this.updateJobStatus(JobStatus.DRIVER_ORDER_PROCESSING);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to DRIVER_ORDER_PROCESSING.' });
  }

  async navigateToDestination() {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.orderQuery.destinationLocation.location.latitude},${this.orderQuery.destinationLocation.location.longitude}`, '_blank');
    await this.updateJobStatuses([JobStatus.DRIVER_STARTED_TRIP, JobStatus.DRIVER_EN_ROUTE]);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to DRIVER_STARTED_TRIP.' });
  }

  async arrivedAtPickup() {
    await this.updateJobStatus(JobStatus.DRIVER_REACHED_PICKUP);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to DRIVER_REACHED_PICKUP.' });
  }

  async loadingStartedAtPickup() {
    await this.updateJobStatuses([JobStatus.DRIVER_DOCUMENT_VERIFICATION,
    JobStatus.JOB_PAYLOAD_LOADING]);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to JOB_PAYLOAD_LOADING.' });
  }

  async loadingCompletedAtPickup() {
    await this.updateJobStatuses([JobStatus.PAYLOAD_QUANTITY_VERIFICATION,
    JobStatus.PAYLOAD_CONDITION_CHECK,
    JobStatus.DELIVERY_DOCUMENTS_UPLOAD,
    JobStatus.PAYLOAD_SEALING_PROCESS,
    JobStatus.DRIVER_ORDER_SIGNOFF]);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to PAYLOAD_CONDITION_CHECK.' });
  }

  async arrivedAtDestination() {
    await this.updateJobStatus(JobStatus.DRIVER_REACHED_DROPOFF);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to DRIVER_REACHED_DROPOFF.' });
  }

  async unloadStartedAtDestination() {
    await this.updateJobStatuses([JobStatus.RECIPIENT_VERIFICATION_PROCESS, JobStatus.JOB_PAYLOAD_UNLOADING]);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to JOB_PAYLOAD_UNLOADING.' });
  }

  async unloadCompletedAtDestination() {
    await this.updateJobStatuses([JobStatus.PAYLOAD_SEALING_CHECK,
    JobStatus.PAYLOAD_QUANTITY_CHECK,
    JobStatus.PAYLOAD_CONDITION_RECHECK]);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to PAYLOAD_CONDITION_RECHECK.' });
  }

  async completeTrip() {
    await this.updateJobStatus(JobStatus.DRIVER_JOB_COMPLETED);
    this.messageService.add({ severity: 'info', summary: 'Status Updated', detail: 'Your status is updated to JOB_COMPLETION_ACKNOWLEDGE.' });
  }

}
