/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Driver } from '../model/driver/driver';
import { APIResponseWrapper } from '../model/common/api-response-wrapper';

@Injectable()
export class DriverService {

    restApiUrl: string

    constructor(private http: HttpClient) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    getDriverById(driverId: string) {
        return this.http.get<APIResponseWrapper<Driver>>(`${this.restApiUrl}driver-endpoint/driver?vehicleInfoRequired=true&cultureCode=en-US&driverId=${driverId}`);
    }

    getDriverByMobileNumber(phoneNumber: string) {
        return this.http.get<APIResponseWrapper<Driver>>(`${this.restApiUrl}driver-endpoint/driver?phoneNumber=${phoneNumber.replace('+','%2B')}`);
    }

    createDriver(driver: Driver) {
        return this.http.post<APIResponseWrapper<Driver>>(`${this.restApiUrl}driver-endpoint/driver`, driver)
    }

    
    
}