import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleMapsModule, MapDirectionsRenderer } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { authInterceptorProviders } from './helper/auth.interceptor';


import * as firebaseui from "firebaseui";
import { FirebaseUIModule, firebase } from "firebaseui-angular";

import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { ContextMenuModule } from 'primeng/contextmenu';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MenubarModule } from 'primeng/menubar';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessageService } from 'primeng/api';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { OrderComponent } from './order/order.component';
import { DeliveryComponent } from './order/delivery/delivery.component';
import { AuthService } from './service/auth.service';
import { OrderDeliveryService } from './service/order-delivery.service';
import { DriverService } from './service/driver.service';
import { BaseMasterDataService } from './service/base-master-data.service';
import { GoogleMapsLibrariesLoaderService } from './service/google-libraries-loader.service';
import { GoogleMapsService } from './service/google-maps-loader.service';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { DataViewModule } from 'primeng/dataview';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PanelMenuModule } from 'primeng/panelmenu';

import { SlideMenuModule } from 'primeng/slidemenu';
import { MenuModule } from 'primeng/menu';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag'
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AccordionModule } from 'primeng/accordion';
import { GalleriaModule } from 'primeng/galleria';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TimelineModule } from 'primeng/timeline';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthRemoteService } from './service/auth-remote.service';


const firebaseUiAuthConfig: firebaseui.auth.Config = {
	signInFlow: 'popup',
	signInOptions: [
		firebase.auth.PhoneAuthProvider.PROVIDER_ID,
	],
	tosUrl: 'https://www.cargomate.co/privacy-policy',
	privacyPolicyUrl: 'https://www.cargomate.co/privacy-policy',
	credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		OrderComponent,
		DeliveryComponent
	],
	imports: [
		AvatarModule,
		TimelineModule,
		GalleriaModule,
		BrowserModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		HttpClientModule,
		AppRoutingModule,
		SlideMenuModule,
		OverlayPanelModule,
		MenuModule,
		FormsModule,
		AccordionModule,
		BreadcrumbModule,
		FontAwesomeModule,
		TableModule,
		MenubarModule,
		InputTextModule,
		DropdownModule,
		InputNumberModule,
		CalendarModule,
		DataViewModule,
		CardModule,
		CheckboxModule,
		ToastModule,
		ImageModule,
		TagModule,
		DialogModule,
		ProgressSpinnerModule,
		ProgressBarModule,
		DividerModule,
		GoogleMapsModule,
		AppRoutingModule,
		PanelMenuModule,

		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			defaultLanguage: 'messages.en'
		}),
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAuthModule,
		FirebaseUIModule.forRoot(firebaseUiAuthConfig),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		AuthService,
		AuthRemoteService,
		/*OrderQueryService,
		OrderJobService,*/
		OrderDeliveryService,
		DriverService,
		/*CompanyService,
		LocationService,
		ReportsService,*/
		BaseMasterDataService,
		GoogleMapsService,
		MessageService,
		//BigQueryService,
		MapDirectionsRenderer,
		GoogleMapsLibrariesLoaderService,
		authInterceptorProviders
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
