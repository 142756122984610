import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DriverService } from './driver.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRemoteService {

  constructor(private http: HttpClient, private driverService: DriverService) { }

  

}
