import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderQueryView } from '../model/order-view/order-query-view';
import { SessionStorageService } from '../service/session-storage.service';
import { MenuItem } from 'primeng/api';
import { DataViewLazyLoadEvent } from 'primeng/dataview';
import { OrderDeliveryService } from '../service/order-delivery.service';
import { OrderDeliveryView } from '../model/order-view/order-delivery';

@Component({
  selector: 'app-list-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  [x: string]: any;

  orderQueryList: OrderQueryView[] = [];
  orderQueryMap: Map<number, OrderQueryView[]> = new Map<number, OrderQueryView[]>();
  isLoading = true;
  totalRecords: number | undefined;
  currentPage: number = 0;
  breadcrumbItems: MenuItem[];
  homeBreadcrumb: MenuItem;

  constructor(private orderDeliveryService: OrderDeliveryService,
    private sessionStorageService: SessionStorageService,
    private router: Router) {
    this.breadcrumbItems = [{ label: 'Orders', routerLink: '/list-order' }];
    this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
  }

  ngOnInit(): void {

  }

  loadOrderList($event: DataViewLazyLoadEvent) {
    this.currentPage = Math.floor($event.first / $event.rows) + 1;
    if (this.orderQueryMap.has(this.currentPage))
      this.orderQueryList = this.orderQueryMap.get(this.currentPage) || [];
    else {
      this.isLoading = true;
      this.orderDeliveryService.getOrdersByDriver(this.currentPage, $event.rows, this.sessionStorageService.getDriver()?.firebaseId!!)
        .subscribe((orders: OrderQueryView[]) => {
          console.log(orders);
          this.orderQueryMap.set(this.currentPage, orders);
          this.orderQueryList = this.orderQueryMap.get(this.currentPage) || [];
          this.isLoading = false;
        });
    }
    this.orderDeliveryService.getOrdersByDriverCount(this.sessionStorageService.getDriver()?.firebaseId!!)
      .subscribe((ordersCount: number) => {
        console.log("Total Records: " + ordersCount);
        this.totalRecords = ordersCount;
      });
  }

  showDate(longDate: number) {
    return formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US');
  }

  viewDetailPage(orderQuery: OrderQueryView): void {
    let orderDeliveryList = Object.entries(orderQuery?.deliveries)
      .map(deliveryEntry => deliveryEntry[1]) as OrderDeliveryView[];
    let orderDelivery = orderDeliveryList[0];

    this.sessionStorageService.setCurrentOrder(orderQuery);
    this.sessionStorageService.setCurrentDelivery(orderDelivery);

    this.router.navigate(
      ['/order/delivery'],
      {
        queryParams: {
          'deliveryNumber': orderDelivery.deliveryText,
          'queryId': orderDelivery.queryId
        }
      }
    );
  }

}
