<p-toast></p-toast>
<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<div class="grid mb-4">
  <div class="lg:col-8 sm:col-12 pt-4 pl-4 pr-4">
    <p class="text-4xl font-bold text-blue-800 p-0 m-0 mb-1">{{ 'deliveryLabel' | translate }} {{orderDelivery.deliveryText}}</p>
    <p-tag icon="pi pi-user" value="{{ orderJobStatus.jobStatus }}"></p-tag>
  </div>
  <div class="lg:col-4 sm:col-12 pt-4 pr-4 pl-4 flex justify-content-end">
    <p class="text-2xl text-right text-red-400 p-0 m-0"><i
        class="fa-solid fa-sack-dollar"></i>&nbsp;&nbsp;{{orderQuery.countryOfOrder?.currencyCode}}
      {{orderQuery.buyingPrice}}</p>
  </div>
</div>



<div *ngIf="!isLoading" class="grid mb-2">
    <div class="col-12 flex justify-content-between align-items-center pl-2 pt-1 pr-2 pb-2"
        *ngIf="orderJobStatus.jobStatusMap['DRIVER_ORDER_PROCESSING'] == null">
        <button pButton pRipple type="button" label="{{ 'navigateToPickup' | translate }}" icon="fa-solid fa-location-arrow"
            class="p-button-info ml-2 mr-2" style="width: 100%;" (click)="navigateToPickup()"></button>
    </div>
    <div class="col-12 lg:col-6">
        <div class="grid bg-red-100 text-gray-900 border-round-md ml-2 mr-2">

            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-2 pr-3">
                <div class="flex align-items-center font-bold text-xl">
                    <i class="fa-solid fa-flag-checkered"></i>&nbsp;&nbsp;{{ 'senderInfo' | translate }}
                </div>
                <p-image src="{{ orderQuery.sourceLocation.company.logo != null ? orderQuery.sourceLocation.company.logo 
                    : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}" height="40"></p-image>
            </div>

            <div class="col-12">
                <div class="flex">
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <p-avatar image="{{ orderQuery.sourceLocation.defaultContact.imageUrl != null ? orderQuery.sourceLocation.defaultContact.imageUrl 
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                shape="circle" class="p-0 pt-2 pb-1"></p-avatar><span class="text-lg">{{
                                orderQuery.sourceLocation.defaultContact.name }}</span>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;{{ 'mobile' | translate }}:&nbsp;&nbsp;
                            <a href="tel:{{ orderQuery.sourceLocation.defaultContact.phoneNumber }}">
                                <b>{{ orderQuery.sourceLocation.defaultContact.phoneNumber }}</b>
                            </a>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-building"></i>&nbsp;&nbsp;{{ 'site' | translate }}:&nbsp;&nbsp;
                            <b>{{ orderQuery.sourceLocation.name }}({{ orderQuery.sourceLocation.company.name }})</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{{ 'address' | translate }}:&nbsp;&nbsp;<a
                                href="https://maps.google.com?q={{ orderQuery.nominationPack.sender.client.location.latitude }},{{ orderQuery.nominationPack.sender.client.location.longitude }}">{{pickupSubLocality}},
                                {{pickupLocality}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-1 pr-3 pb-4"
                *ngIf="orderJobStatus.jobStatusMap['DRIVER_REACHED_PICKUP'] == null  && orderJobStatus.jobStatusMap['DRIVER_ORDER_PROCESSING'] != null">
                <button pButton pRipple type="button" label="{{ 'arrived' | translate }}" icon="fa-solid fa-plane-arrival"
                    class="p-button-success" style="width: 100%;" (click)="arrivedAtPickup()"></button>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6">
        <div class="grid bg-purple-100 text-gray-900 border-round-md ml-2 mr-2">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-truck-ramp-box"></i>&nbsp;&nbsp;{{ 'cargoInfo' | translate }}
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ orderQuery!!.payloadItemList[0]!!.itemType?.image ? orderQuery!!.payloadItemList[0]!!.itemType?.image
                                : 'https://cdn-icons-png.flaticon.com/512/3285/3285047.png' }}"
                            height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3 pt-2">
                            <i class="fa-solid fa-wrench"></i>&nbsp;&nbsp;{{ 'type' | translate }}#:&nbsp;&nbsp;<b>{{
                                orderQuery!!.payloadItemList[0]!!.itemType?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <i class="fa-solid fa-gear"></i>&nbsp;&nbsp;{{ 'quantity' | translate }}:&nbsp;&nbsp;
                            <b>{{ orderQuery!!.payloadItemList[0]!!.itemQuantity?.quantity }} {{
                                orderQuery!!.payloadItemList[0]!!.itemQuantity?.uom?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-weight-hanging"></i>&nbsp;&nbsp;{{ 'weight' | translate }}:&nbsp;&nbsp;<b>{{
                                orderQuery!!.payloadItemList[0]!!.itemWeight }} {{
                                orderQuery!!.payloadItemList[0]!!.weightUnit?.shortName }}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-1 pr-3 pb-2"
                *ngIf="orderJobStatus.jobStatusMap['JOB_PAYLOAD_LOADING'] == null  && orderJobStatus.jobStatusMap['DRIVER_REACHED_PICKUP'] != null">
                <button pButton pRipple type="button" label="{{ 'loadingStarted' | translate }}" icon="fa-solid fa-truck-ramp-box"
                    class="p-button-warning" style="width: 100%;" (click)="loadingStartedAtPickup()"></button>
            </div>
            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-1 pr-3 pb-4"
                *ngIf="orderJobStatus.jobStatusMap['DRIVER_ORDER_SIGNOFF'] == null  && orderJobStatus.jobStatusMap['JOB_PAYLOAD_LOADING'] != null">
                <button pButton pRipple type="button" label="{{ 'loadingCompleted' | translate }}" icon="fa-solid fa-list-check"
                    class="p-button-help" style="width: 100%;" (click)="loadingCompletedAtPickup()"></button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="grid mb-2">
    <div class="col-12 lg:col-6 pr-3 pl-3">
        <google-map #map id="map-canvas" height="240px" width="100%" [zoom]="zoom" [center]="center"
            [options]="options">

            <map-marker #vehicleMarker [position]="vehicleLocation" [title]="'{{ vehicleLocation | translate }}'"
                [icon]="vehicleMarkerImage" [options]="options" (mapClick)="openInfo(vehicleMarker)">
                <map-info-window>{{ vehicleLocation.lat }}, {{ vehicleLocation.lng }}</map-info-window>
            </map-marker>

            <map-marker #pickupMarker [position]="pickupLocation" [title]="'{{ sourceLocation | translate }}'" [options]="options"
                (mapClick)="openInfo(pickupMarker)">
                <map-info-window>{{ pickupLocation.lat }}, {{ pickupLocation.lng }}</map-info-window>
            </map-marker>

            <map-marker #dropoffMarker [position]="dropoffLocation" [title]="'{{ destinationLocation | translate }}'" [options]="options"
                (mapClick)="openInfo(dropoffMarker)">
                <map-info-window>{{ dropoffLocation.lat }}, {{ dropoffLocation.lng }}</map-info-window>
            </map-marker>

            <map-directions-renderer *ngIf="showRoute" [directions]="directionsResults">
            </map-directions-renderer>
        </google-map>
    </div>
    <div class="col-12 flex justify-content-between align-items-center pl-2 pr-2 pb-3"
        *ngIf="orderJobStatus.jobStatusMap['DRIVER_EN_ROUTE'] == null  && orderJobStatus.jobStatusMap['DRIVER_ORDER_SIGNOFF'] != null">
        <button pButton pRipple type="button" label="{{ 'navigateToDestination' | translate }}" icon="fa-solid fa-location-arrow"
            class="p-button-info ml-2 mr-2" style="width: 100%;" (click)="navigateToDestination()"></button>
    </div>
    <div class="col-12 lg:col-6">
        <div class="grid bg-green-100 text-gray-900 border-round-md ml-2 mr-2">
            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-2 pr-3">
                <div class="flex align-items-center font-bold text-xl">
                    <i class="fa-solid fa-flag"></i>&nbsp;&nbsp;{{ 'receiverInfo' | translate }}
                </div>
                <p-image src="{{ orderQuery.destinationLocation.company.logo != null ? orderQuery.destinationLocation.company.logo 
                    : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}" height="40"></p-image>
            </div>

            <div class="col-12">
                <div class="flex">
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <p-avatar image="{{ orderQuery.destinationLocation.defaultContact.imageUrl != null ? orderQuery.destinationLocation.defaultContact.imageUrl 
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                shape="circle" class="p-0 pt-2 pb-1"></p-avatar><b class="text-lg">{{
                                orderQuery.destinationLocation.defaultContact.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;{{ 'mobile' | translate }}:&nbsp;&nbsp;
                            <a href="tel:{{ orderQuery.destinationLocation.defaultContact.phoneNumber }}">
                                <b>{{ orderQuery.destinationLocation.defaultContact.phoneNumber }}</b>
                            </a>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-building"></i>&nbsp;&nbsp;{{ 'site' | translate }}:&nbsp;&nbsp;
                            <b>{{ orderQuery.destinationLocation.name }}({{ orderQuery.destinationLocation.company.name
                                }})</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{{ 'address' | translate }}:&nbsp;&nbsp;<a
                                href="https://maps.google.com?q={{ orderQuery.destinationLocation.location.latitude }},{{ orderQuery.destinationLocation.location.longitude }}">{{dropoffSubLocality}},
                                {{dropoffLocality}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-1 pr-3 pb-2"
                *ngIf="orderJobStatus.jobStatusMap['DRIVER_REACHED_DROPOFF'] == null  && orderJobStatus.jobStatusMap['DRIVER_EN_ROUTE'] != null">
                <button pButton pRipple type="button" label="{{ 'arrived' | translate }}" icon="fa-solid fa-plane-arrival"
                    class="p-button-success" style="width: 100%;" (click)="arrivedAtDestination()"></button>
            </div>
            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-1 pr-3 pb-2"
                *ngIf="orderJobStatus.jobStatusMap['JOB_PAYLOAD_UNLOADING'] == null  && orderJobStatus.jobStatusMap['DRIVER_REACHED_DROPOFF'] != null">
                <button pButton pRipple type="button" label="{{ 'unloadingStarted' | translate }}" icon="fa-solid fa-truck-ramp-box"
                    class="p-button-warning" style="width: 100%;" (click)="unloadStartedAtDestination()"></button>
            </div>
            <div class="col-12 flex justify-content-between align-items-center pl-3 pt-1 pr-3 pb-4"
                *ngIf="orderJobStatus.jobStatusMap['PAYLOAD_CONDITION_RECHECK'] == null  && orderJobStatus.jobStatusMap['JOB_PAYLOAD_UNLOADING'] != null">
                <button pButton pRipple type="button" label="{{ 'unloadingCompleted' | translate }}" icon="fa-solid fa-list-check"
                    class="p-button-help" style="width: 100%;" (click)="unloadCompletedAtDestination()"></button>
            </div>
        </div>
    </div>
</div>

<div class="grid">
  <div class="col-12 lg:col-6">
    <div class="grid bg-yellow-100 text-gray-900 border-round-md ml-2 mr-2"
      *ngIf="orderJobStatus.jobStatusMap['JOB_COMPLETION_ACKNOWLEDGE'] == null  && orderJobStatus.jobStatusMap['PAYLOAD_CONDITION_RECHECK'] != null">
      <div class="col-12">
        <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
          <i class="fa-solid fa-file-pdf"></i>&nbsp;&nbsp;{{ 'signedWaybillUpload' | translate }}
        </div>
      </div>
      <div class="col-12">
        <p-progressBar [value]="pdfUploadProgress" class="m-2" *ngIf="pdfUploadOngoing"></p-progressBar>
      </div>
      <div class="col-12 flex flex-wrap gap-2 pl-2 pr-2 pb-4 pt-2">
        <button pButton pRipple (click)="openCamera()" 
          [label]="'captureImage' | translate" 
          icon="pi pi-camera"
          class="p-button-danger" style="width: 100%;"></button>
        <input #fileUpload type="file" accept="image/*" capture="environment" (change)="onFileSelected($event)" hidden>
      </div>
      <div class="col-12 flex flex-wrap gap-2 pl-2 pr-2 pb-4 pt-2">
        <button *ngIf="selectedImage" pButton pRipple (click)="uploadImage()" 
          [label]="'uploadImage' | translate" 
          icon="pi pi-upload" class="p-button-help" style="width: 100%;"></button>
      </div>
      <div class="mt-4 pl-4 pr-4 pb-4" *ngIf="selectedImage">
        <img [src]="selectedImage" style="max-width: 300px;">
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-6 pr-3 pl-3">
    <p-table [value]="deliveryFilesList">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          {{ 'deliveryFiles' | translate }}
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'fileName' | translate }}</th>
          <th>{{ 'fileType' | translate }}</th>
          <th>{{ 'createdAt' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-deliveryFile>
        <tr>
          <td><a href="{{deliveryFile.fileUrl}}">{{deliveryFile.fileName}}</a></td>
          <td>{{deliveryFile.fileType}}</td>
          <td>{{showDate(deliveryFile.createdAt)}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
          {{ 'totalFiles' | translate: {count: deliveryFilesCount} }}
        </div>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 flex justify-content-between align-items-center pl-2 pr-2 pb-3"
    *ngIf="orderJobStatus.jobStatusMap['DRIVER_JOB_COMPLETED'] == null  && orderJobStatus.jobStatusMap['JOB_COMPLETION_ACKNOWLEDGE'] != null">
    <button pButton pRipple type="button" 
      [label]="'completeTrip' | translate" 
      icon="fa-solid fa-square-check"
      class="p-button-success ml-2 mr-2" style="width: 100%;" (click)="completeTrip()"></button>
  </div>
</div>
