import { Component, Renderer2 } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { getDatabase, ref, DatabaseReference } from 'firebase/database';
import { GeoFire } from 'geofire';
import { database } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { pwaInstallHandler } from 'pwa-install-handler';

import { Driver } from './model/driver/driver';
import { SessionStorageService } from './service/session-storage.service';
import { VehicleKeyPack } from './service/vehicle-key-pack.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LangDirectionService } from './service/lang-direction.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cargomate-driver-tracker';

  mainMenuItems: MenuItem[] = [];
  driver: Driver | undefined;
  currentPosition: GeolocationPosition | null = null;
  locationSubscription: number | null = null;
  showPWAInstallButton: boolean = false;

  constructor(private angularFireAuth: AngularFireAuth,
    private angularFireDatabase: AngularFireDatabase,
    private router: Router, private translate: TranslateService,
    private langDirectionService: LangDirectionService,
    private renderer: Renderer2,
    private sessionStorageService: SessionStorageService) {
    translate.setDefaultLang('messages.en');
    this.setDirection()
  }

  ngOnInit() {
    this.mainMenuItems = [
      {
        label: 'Orders',
        icon: 'pi pi-fw pi-arrow-right-arrow-left',
        routerLink: '/order'
      },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
          this.angularFireAuth.signOut();
          this.driver = undefined;
          sessionStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    ];
    this.mainMenuItems = [
      {
        label: 'Orders',
        icon: 'pi pi-fw pi-arrow-right-arrow-left',
        routerLink: '/order'
      },
      {
        label: 'Language',
        items: [
          {
            label: 'English',
            icon: 'pi pi-align-left',
            command: () => {
              this.translate.use('messages.en');
              this.langDirectionService.toggleToLTR();
              this.setDirection();
            }
          },
          {
            label: 'عربي',
            icon: 'pi pi-align-right',
            command: () => {
              this.translate.use('messages.ar');
              this.langDirectionService.toggleToRTL();
              this.setDirection();
            }
          },
          {
            label: 'اردو',
            icon: 'pi pi-align-right',
            command: () => {
              this.translate.use('messages.ur');
              this.langDirectionService.toggleToRTL();
              this.setDirection();
            }
          }
        ]
      },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
          this.angularFireAuth.signOut();
          this.driver = undefined;
          sessionStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    ];
    this.angularFireAuth.authState.subscribe(response => {
      if (response) {
        console.log('Logged in :)');
        this.driver = this.sessionStorageService.getDriver();;
      } else {
        console.log('Logged out :(');
      }
    });
    this.driver = this.sessionStorageService.getDriver();
    this.startTrackingLocation();
    pwaInstallHandler.addListener((canInstall) => {
      this.showPWAInstallButton = canInstall;
    })
  }

  ngOnDestroy(): void {
    this.stopTrackingLocation();
  }

  startTrackingLocation() {
    if (navigator.geolocation) {
      this.locationSubscription = navigator.geolocation.watchPosition((position) => {
        this.currentPosition = position;
        console.log("Current Position:", this.currentPosition);

        let driver = this.sessionStorageService.getDriver();
        if (driver) {
          let vehicleKeyPack = new VehicleKeyPack().fromData(driver.currentVehicle!);
          let keyPackString = vehicleKeyPack.toString();
          console.log('keypack string: ' + keyPackString);
          const geoFire = new GeoFire(ref(database, 'vehicle/location/all'));
          try {
            geoFire.set(keyPackString, [position.coords.latitude, position.coords.longitude]).then(res => console.log('GeoFire updated!'));
            this.angularFireDatabase.object(`vehicle/location/lastUpdated/${driver.currentVehicle?.vehicleId}`).set(this.currentPosition.timestamp);
          } catch (e) {
            //console.log(e);
          }
        }
      },
        (error) => {
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 } // Optional: Customize options
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      // Handle the case where geolocation is not supported
    }
  }

  stopTrackingLocation() {
    if (this.locationSubscription) {
      navigator.geolocation.clearWatch(this.locationSubscription);
      this.locationSubscription = null;
    }
  }

  private setDirection() {
    this.renderer.setProperty(document.documentElement, '--direction', this.langDirectionService.direction);
  }

  installPWA() {
    pwaInstallHandler.install();
  }
}
