import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OrderComponent } from './order/order.component';
import { DeliveryComponent } from './order/delivery/delivery.component';

const orderRoute: Route = {
  path: 'order', children: [
    {
      path: '',
      component: OrderComponent,
    },
    {
      path: 'delivery',
      component: DeliveryComponent
    }
  ]
};
const loginRoute: Route = { path: 'login', component: LoginComponent };
export const routes: Routes = [orderRoute, loginRoute];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
