import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LangDirectionService {
  private isRTL = false;

  get direction(): 'ltr' | 'rtl' {
    return this.isRTL ? 'rtl' : 'ltr';
  }

  toggleToLTR() {
    this.isRTL = false;
  }

  toggleToRTL() {
    this.isRTL = true;
  }

}
