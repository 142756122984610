import * as firebaseAuth from 'firebase/auth';
import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { SessionStorageService } from '../service/session-storage.service';
import { catchError, firstValueFrom, observable, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private sessionStorageService: SessionStorageService,
        private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        let token = this.sessionStorageService.getAccessToken();
        authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        return next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x, req, next)));
    }

    handleAuthError(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable <any> {
        //handle your auth error or rethrow
        if(err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            if (this.sessionStorageService.getAccessToken() === null 
                || this.sessionStorageService.getAccessToken() === undefined) {
                    let returnUrl = window.location.href;
                    returnUrl = returnUrl.substring(this.findThirdOccurrence(returnUrl, '/'), returnUrl.length);
                    this.sessionStorageService.saveReturnUrl(returnUrl);
                    this.router.navigate(['/login']);
                }
            else return new Observable((observer) => {
                this.authService.getCurrentUserAccessToken().subscribe(accessToken => {
                    this.sessionStorageService.saveAccessToken(accessToken, new Date().getTime());
                    observer.next(this.intercept(req, next));
                    window.location.reload();
                })
            });
            // if you've caught / handled the error, you don't want to rethrow
            // it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }

    findThirdOccurrence(str: string, char: string): number {
        let count = 0;
        let index = -1;
    
        for (let i = 0; i < str.length; i++) {
          if (str[i] === char) {
            count++;
            if (count === 3) {
              index = i;
              break;
            }
          }
        }
    
        return index;
      }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];