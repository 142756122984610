import * as firebaseAuth from 'firebase/auth';
import * as angularAuth from "@angular/fire/compat/auth";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    userData: Observable<firebaseAuth.User>;

    constructor(private angularFireAuth: angularAuth.AngularFireAuth) {
        this.userData = angularFireAuth.authState as any;
    }

    login(emailAddress: string, password: string): Observable<any> {
        return new Observable((observer) => {
            this.angularFireAuth
                .signInWithEmailAndPassword(emailAddress, password)
                .then(res => {
                    console.log("You're in !");
                    observer.next(res.user);
                })
                .catch(err => {
                    console.log('Something went wrong:', err.message);
                    observer.error(err);
                });
        });
    }

    register(email: string, password: string): Observable<any> {
        return new Observable((observer) => {
            this.angularFireAuth
                .createUserWithEmailAndPassword(email, password)
                .then(res => {
                    console.log('You are Successfully signed up!', res);
                    observer.next(res.user);
                })
                .catch(error => {
                    console.log('Something is wrong:', error.message);
                    observer.error(error);
                });
        });
    }

    getCurrentUserAccessToken(): Observable<string> {
        return new Observable((observer) => {
            this.angularFireAuth.currentUser.then(res => {
                console.log('Access Token: ', res?.getIdToken());
                res?.getIdToken(true).then(res => {
                    console.log('Access Token: ', res?.valueOf());
                    observer.next(res?.valueOf());
                })
                .catch(error => {
                    console.log('Something is wrong:', error.message);
                    observer.error(error);
                });
            })
            .catch(error => {
                console.log('Something is wrong:', error.message);
                observer.error(error);
            });
        });
    }

    getCurrentUserRefreshToken(): Observable<string> {
        return new Observable((observer) => {
            this.angularFireAuth.currentUser.then(res => {
                console.log('Refresh Token: ', res?.getIdToken());
                let userRefreshToken = res?.refreshToken;
                if (userRefreshToken !== null) {
                    console.log('Refresh Token: ', userRefreshToken);
                    observer.next(userRefreshToken);
                } else {
                    console.log('Something is wrong:');
                    observer.error(new Error("refresh token is null"));
                }
            })
            .catch(error => {
                console.log('Something is wrong:', error.message);
                observer.error(error);
            });
        });
    }

    verifyOrRefreshUserAccessToken(refreshToken: string): Observable<string> {
        return new Observable((observer) => {
            this.angularFireAuth.  currentUser.then(res => {
                console.log('Refresh Token: ', res?.getIdToken());
                let userRefreshToken = res?.refreshToken;
                if (userRefreshToken !== null) {
                    console.log('Refresh Token: ', userRefreshToken);
                    observer.next(userRefreshToken);
                } else {
                    console.log('Something is wrong:');
                    observer.error(new Error("refresh token is null"));
                } 
            })
            .catch(error => {
                console.log('Something is wrong:', error.message);
                observer.error(error);
            });
        });
    }

    logout() {
        this.angularFireAuth.signOut();
    }
}