/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { OrderQueryView } from "../model/order-view/order-query-view";
import { APIResponseWrapper } from '../model/common/api-response-wrapper';
import { JobAttachment } from '../model/order-view/job-attachment';

@Injectable()
export class OrderDeliveryService {

    transactionApiUrl: string;

    constructor(private http: HttpClient) {
        this.transactionApiUrl = `${environment.reactiveApiUrl}`;
    }

    getOrdersByDriver(page: number, pageSize: number, driverId: string) {
        return this.http.get<OrderQueryView[]>(`${this.transactionApiUrl}deliveries/driver/${driverId}/orders/paginated`
            + "?page=" + (page-1)
            + "&pageSize=" + pageSize
            + "&isPayloadItemsNeeded=" + "true"
            + "&isOrderActionDataNeeded=" + "true"
            + "&isNominationsNeeded=" + "true"
            + "&isFilesNeeded=" + "true"
            + "&cultureCode=" + "en-US");
    }

    getOrdersByDriverCount(driverId: string) {
        return this.http.get<number>(`${this.transactionApiUrl}deliveries/driver/${driverId}/orders/count`);
    }

    getDeliveryByOrderAndDeliveryNumber(queryId: string, deliveryText: string) {
        return this.http.get<OrderQueryView>(`${this.transactionApiUrl}deliveries/order/${queryId}/delivery/${deliveryText}`
            + "?isDriverAndVehicleNeeded=" + "true"
            + "&isPayloadItemsNeeded=" + "true"
            + "&isNominationsNeeded=" + "true"
            + "&isOrderActionDataNeeded=" + "true"
            + "&isFilesNeeded=" + "true"
            + "&cultureCode=" + "en-US");
    }

    addDeliveryAttachment(jobAttachment: JobAttachment, queryId: string, deliveryText: string) {
        return this.http.put<APIResponseWrapper<Boolean>>(`${this.transactionApiUrl}deliveries/order/${queryId}/delivery/${deliveryText}/attachment`, jobAttachment);
    }

}