<p-toast></p-toast>
<div class="flex align-items-center flex-wrap">
  <div class="flex align-items-center justify-content-center" style="width: 100%;">
    <h1>CargoMate Driver Tracker</h1>
  </div>
  <div class="flex align-items-center justify-content-center" style="width: 100%;">
    <h2>Login</h2>
  </div>
  <div class="flex align-items-center justify-content-center" style="width: 100%;">
    <firebase-ui (signInSuccessWithAuthResult)="successCallback($event)" (signInFailure)="errorCallback($event)"
      (uiShown)="uiShownCallback()"></firebase-ui>
  </div>
</div>