<p-menubar>
  <ng-template pTemplate="start">
    <img src="../assets/cargomate_logo.png" height="50" class="mr-2">
  </ng-template>
  <ng-template pTemplate="end">
    <div class="card-container blue-container overflow-hidden m-0 cursor-pointer">
      <div class="flex">
        <div class="flex-initial flex align-items-center justify-content-center bg-gray-100 font-bold border-round-lg">
          <p-avatar image="{{ driver?.imageUrl != null ? driver?.imageUrl : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2" size="large" shape="circle"
            class="p-2"></p-avatar>
          <span class="block text font-bold text-brown-500 pr-3">{{ driver?.legalName }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</p-menubar>
<div class="flex flex-wrap bg-yellow-100 text-gray-900 border-round-md mt-2">
  <div class="flex-none flex align-items-center justify-content-center ml-3">
    <h3>{{ 'installAppMessage' | translate }}</h3>
  </div>
  <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
  <div class="flex-none flex align-items-center justify-content-center m-2">
    <p-button label="{{ 'installAppButton' | translate }}" icon="pi pi-angle-double-down" (onClick)="installPWA()"
      class="p-2"></p-button>
  </div>
</div>
<div class="card flex justify-content-center p-2 w-full">
  <p-panelMenu [model]="mainMenuItems" [style]="{'width':'350px'}" [multiple]="true"></p-panelMenu>
</div>
<router-outlet></router-outlet>